<template>
    <div class="homeOrder">
        <div class="title">我的售后</div>
        <div v-if="afterSalesList.length == 0"
            style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
            <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
            <div style="width: 100%;margin-top: 10px;">暂无数据</div>
        </div>
        <div class="homeSales" v-for="(i, index) in afterSalesList" :key="index">
            <div class="topHead">
                <div class="bhao">退款编号</div>
                <div class="text">{{ i.refundTradeNo || '--' }}</div>
            </div>
            <div class="comt">
                <div class="name">
                    <div class="img">
                        <img :src="i.mallStore.storeLog" alt="">
                    </div>
                    <div class="namee">{{ i.mallStore.storeName }}</div>
                </div>
                <div class="specifications">申请时间 <br> {{ i.createTime }}</div>
                <div class="num">{{ i.statusDesc }}</div>
                <div class="realPay">
                    <div class="bt" @click="checkTheDetails(i)">查看详情</div>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <paging style="width: 100%;" :total="pading.total" @handleCurrentChange="handleCurrentChange" :currentPage="pading.current"
            :page-size="pading.size"></paging>
    </div>
</template>

<script>
import paging from "@/components/paging.vue";
import { getAfterSalesList } from '@/utils/api/myApi/index'
export default {
    name: 'myAfterSales',
    components: {
        paging
    },
    data() {
        return {
            pading: {
                current: 1,
                size: 5,
                total: 0
            },
            // 列表数据
            afterSalesList: []
        }
    },
    mounted() {
        this.getAfterSales()
    },
    methods: {
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.pading.current = val
            this.getAfterSales()
        },
        // 获取列表数据
        async getAfterSales() {
            const res = await getAfterSalesList({
                current: this.pading.current,
                size: this.pading.size,
            })
            if (res.statusCode == 8201) {
                this.afterSalesList = res.data.records
                this.pading.total = res.data.total
            }
        },
        // 查看详情
        checkTheDetails(i) {
            this.$router.push(`/myIndex/afterTheDetails?actMyNum=1&returnsId=${i.returnsId}`)
        }
    }
}
</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;

    }

    >.homeSales {
        width: calc(100% - 18px);
        margin-left: 18px;
        height: 133px;
        border: 1px solid #CCCCCC;
        margin-top: 22px;

        >.topHead {
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 38px;
            color: #333;
            font-size: 14px;
            padding: 15px;
            box-sizing: border-box;

            >.bhao {
                margin-right: 20px;
            }

        }

        >.comt {
            width: 100%;
            height: 95px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #333;
            font-size: 14px;
            background-color: #fff;

            >.name {
                width: 440px;
                height: 100%;
                display: flex;
                padding: 15px;
                box-sizing: border-box;
                border-right: 1px solid rgba(151, 151, 151, 0.50);

                >.img {
                    width: 64px;
                    height: 64px;
                    margin-right: 18px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.namee {
                    text-align: left;
                    width: 210px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-height: 2.6rem;
                }
            }

            >.specifications {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 220px;
                height: 100%;
                border-right: 1px solid rgba(151, 151, 151, 0.50);
                font-size: 14px;
                color: #999;
            }

            >.num {
                width: 160px;
                line-height: 95px;
                height: 100%;
                border-right: 1px solid rgba(151, 151, 151, 0.50);
                color: #FF4242;
            }

            >.realPay {
                width: 217px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                >.bt {
                    width: 90px;
                    height: 30px;
                    background: #FF4242;
                    border-radius: 1px 1px 1px 1px;
                    font-size: 14px;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                }
            }
        }

    }

}
</style>